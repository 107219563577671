import React, { useLayoutEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AppRoutes } from './routes/routes';
import { Header } from './components/features/home/Header';
import { Footer } from './components/features/home/Footer';
import { useLocation } from 'react-router';
import { excludeHeader } from './utils/excludeHeader';
import styled from 'styled-components';
import { excludeFooter } from './utils/excludeFooter';
import { MenuDesktop } from './components/features/menu/MenuDesktop';
import { ScreenSize, useScreenSize } from './hooks/useScreenSize';
import { MaintenancePage } from './pages/MaintenancePage';
import { useMaintenance } from './hooks/useMaintenance';
import { ActivityIndicator } from './components/common/ActivityIndicator';
import { Color } from './assets/constants';
import { usePostHog } from 'posthog-js/react';

const isAdminRoute = (path: string) => path === '/admin-panel';

export const App = () => {
  const [isInMaintenanceMode, setIsInMaintenanceMode] =
    useState<boolean>(false);
  const location = useLocation();
  const screenSize = useScreenSize();
  const { getMaintenanceStatus, isLoading: isFetchingMaintenanceStatus } =
    useMaintenance();
  const posthog = usePostHog();

  posthog.capture('test_event');

  const currentPath = location.pathname;

  const setMaintenanceStatusFromFirebase = async () => {
    const maintenanceStatus = await getMaintenanceStatus();
    setIsInMaintenanceMode(maintenanceStatus);
  };

  useLayoutEffect(() => {
    setMaintenanceStatusFromFirebase();
  }, []);

  const isSmallScreen = screenSize === ScreenSize.SMALL;
  const showMenuDesktop = !isSmallScreen && currentPath !== '/admin-panel';

  const excludeFooterArray = ['/admin-panel'];
  if (!isSmallScreen) {
    excludeFooterArray.push('/products');
  }

  if (isFetchingMaintenanceStatus) {
    return (
      <ActivityIndicatorWrapper>
        <ActivityIndicator size={200} color={Color.ACCENT} />
      </ActivityIndicatorWrapper>
    );
  }

  if (isInMaintenanceMode && !isAdminRoute(currentPath)) {
    return <MaintenancePage />;
  }

  return (
    <>
      <StyledToast
        position={'top-center'}
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
        stacked
      />
      <AppContainer>
        <DesktopLayout>
          {showMenuDesktop && (
            <div>
              <MenuDesktop />
            </div>
          )}
          <Content>
            {excludeHeader(currentPath) && <Header />}
            <AppRoutes />
          </Content>
        </DesktopLayout>
        {excludeFooter(currentPath) && <Footer />}
      </AppContainer>
    </>
  );
};

const StyledToast = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 30px;
  }
`;

const DesktopLayout = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
  }
`;

const Content = styled.div`
  @media (min-width: 768px) {
    width: 800px;
  }

  @media (min-width: 1366px) {
    width: 1000px;
  }

  @media (min-width: 1600px) {
    width: 1200px;
  }
`;

const ActivityIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100svh;

  @media (min-width: 768px) {
    align-items: center;
  }
`;
