import React from 'react';
import { ProductList } from '../components/features/home/ProductList';
import { ProductListContainer } from '../containers/HomeContainer';
import { Product } from '../domain/models/ProductDTO';
import { EmptyProductList } from '../components/common/EmptyList';
import styled from 'styled-components';
import { Color } from '../assets/constants';

export const FavoritesPage = () => {
  const localStorageProducts: Product[] = JSON.parse(
    localStorage.getItem('favorites') || '[]'
  );

  return (
    <>
      <Row>
        <Title>Любими</Title>
        <RedHeartIcon src={require('../assets/images/red-heart-icon.png')} />
      </Row>
      <ProductListContainer>
        {localStorageProducts.length === 0 ? (
          <EmptyProductList />
        ) : (
          <ProductList
            products={localStorageProducts}
            allProductsHaveBeenFetched
          />
        )}
      </ProductListContainer>
    </>
  );
};

const RedHeartIcon = styled.img`
  width: 30px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1.5rem;
  width: 100%;
`;
