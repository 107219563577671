import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import './styles/reset.scss';
import { store } from './store';
import { Provider } from 'react-redux';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

// if (typeof window !== 'undefined') {
//   posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY ?? '', {
//     api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
//     person_profiles: 'identified_only'
//   });
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>
);
