import React, { useEffect, useState } from 'react';
import { HomeContainer } from '../containers/HomeContainer';
import { Product } from '../domain/models/ProductDTO';
import { useProducts } from '../hooks/useProducts';
import { useAppSelector } from '../hooks/useRedux';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { useAlgolia } from '../hooks/useAlgolia';
import { isDev } from '../utils/environment';

export const HomePage = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [allProductsHaveBeenFetched, setAllProductsHaveBeenFetched] =
    useState<boolean>(false);
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData>>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);

  const { searchTerm } = useAppSelector((state) => state.search);
  const {
    getAllProducts,
    getNextBatch,
    queryProducts,
    isLoading: isFetchingProducts
  } = useProducts();
  const { search } = useAlgolia();

  const setProductsFromFirebase = async () => {
    setAllProductsHaveBeenFetched(false);

    if (searchTerm !== '') {
      const { products: filteredProducts, lastDoc } = await queryProducts(
        4,
        searchTerm
      );
      setProducts(filteredProducts);
      setLastDoc(lastDoc);
    } else {
      const { products, lastDoc } = await getAllProducts(4);
      setProducts(products);
      setLastDoc(lastDoc);
    }
  };

  const setNextBatch = async () => {
    if (!lastDoc) {
      return;
    }

    const { nextBatch, lastDocFromBatch } = await getNextBatch(
      4,
      lastDoc,
      searchTerm
    );
    setLastDoc(lastDocFromBatch);

    if (nextBatch.length === 0) {
      setAllProductsHaveBeenFetched(true);
      return;
    }
    setProducts([...products, ...nextBatch]);
  };

  const loadProducts = async (reset?: boolean) => {
    setAllProductsHaveBeenFetched(false);

    if (reset) {
      if (numberOfPages === 0) {
        return setAllProductsHaveBeenFetched(true);
      }
    }

    const searchResult = await search({
      searchTerm,
      currentPage: reset ? 0 : currentPage,
      numberOfPages
    });

    if (!searchResult) {
      setAllProductsHaveBeenFetched(true);
      return;
    }

    const { products: foundProducts, page, nbPages } = searchResult;

    if (nbPages === 0) {
      setProducts([]);
      setNumberOfPages(1);
      setAllProductsHaveBeenFetched(true);
      return;
    }

    setProducts(reset ? foundProducts : [...products, ...foundProducts]);
    setCurrentPage((page as number) + 1);
    setNumberOfPages(nbPages as number);
  };

  useEffect(() => {
    if (isDev()) {
      setProductsFromFirebase();
      if (searchTerm === '') {
        setAllProductsHaveBeenFetched(false);
      }
    } else {
      loadProducts(true);
    }
  }, [searchTerm]);

  return (
    <HomeContainer
      products={products}
      onLoadMore={isDev() ? setNextBatch : loadProducts}
      allProductsHaveBeenFetched={allProductsHaveBeenFetched}
      searchTerm={searchTerm === ''}
      isLoading={isFetchingProducts}
    />
  );
};
