import React from 'react';
import styled from 'styled-components';
import { Color } from '../../assets/constants';

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  maxLength?: number;
  height?: number;
}

export const Textarea = ({
  value,
  onChange,
  placeholder,
  maxLength,
  height
}: Props) => {
  return (
    <StyledTextarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      maxLength={maxLength}
      height={height}
    />
  );
};

const StyledTextarea = styled.textarea<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => (height ? height : 120)}px;
  padding: 1rem;
  background-color: ${Color.LIGHT_GRAY};
  border-radius: 15px;
  outline: none;
  resize: none;
`;
