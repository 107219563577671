import { useState } from 'react';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

interface MailGunResponse {
  status: number;
  id: string;
  message: string;
}

export interface EmailProps {
  to: string | string[];
  subject: string;
  text: string;
  html: string;
}

export const useMailgun = () => {
  const [response, setResponse] = useState<MailGunResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const url = `${REACT_APP_TENISKI_API_BASE_URL}/mailgun/sendEmail`;

  const sendEmail = async (props: EmailProps) => {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(props)
      });
      const data = await response.json();
      setResponse(data);
      setIsLoading(false);
    } catch (e: unknown) {
      console.log('error', (e as Error).message);
    }
  };

  return { sendEmail, response, isLoading };
};
