import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../assets/constants';
import Switch from '../../components/common/Switch';
import { useMaintenance } from '../../hooks/useMaintenance';
import { ActivityIndicator } from '../../components/common/ActivityIndicator';

export const MaintenanceModeContainer = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean | null>(
    null
  );
  const { getMaintenanceStatus, toggleMaintenanceMode, isLoading } =
    useMaintenance();

  const handleToggleMaintenanceMode = () => {
    toggleMaintenanceMode(!isMaintenanceMode);
    setIsMaintenanceMode((state) => !state);
  };

  const setMaintenanceStatusFromFirebase = async () => {
    const maintenanceStatus = await getMaintenanceStatus();
    setIsMaintenanceMode(maintenanceStatus);
  };

  useEffect(() => {
    setMaintenanceStatusFromFirebase();
  }, []);

  return (
    <Wrapper>
      {isMaintenanceMode !== null ? (
        <Switch
          isOn={isMaintenanceMode}
          handleToggle={handleToggleMaintenanceMode}
        />
      ) : (
        <ActivityIndicator size={100} color={Color.ACCENT} />
      )}
      <Row>
        {isLoading ? (
          <ActivityIndicator size={100} color={Color.ACCENT} />
        ) : (
          <>
            <Text>Maintenance mode is </Text>
            <BoldText>{isMaintenanceMode ? 'ON' : 'OFF'}</BoldText>
          </>
        )}
      </Row>
    </Wrapper>
  );
};

const BoldText = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${Color.WHITE};
`;

const Text = styled.p`
  font-size: 16px;
  color: ${Color.WHITE};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
  height: 200px;
`;
