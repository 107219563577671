import {
  getDiscountForProduct,
  TShirtColor
} from '../../../containers/adminPanel/utils';
import {
  CartProduct,
  mapProductToCartProduct
} from '../../../domain/mappers/cartProductMapper';
import { Product, TShirtType } from '../../../domain/models/ProductDTO';
import { Discount } from '../../../hooks/useDiscounts';
import { LocalItem } from '../../../store/cartSlice';
import { getDiscountedPrice } from '../checkout/utils';

export const translateColorToBulgarian = (color: TShirtColor): string => {
  switch (color) {
    case TShirtColor.WHITE:
      return 'Бял';
    case TShirtColor.BLACK:
      return 'Черен';
    case TShirtColor.RED:
      return 'Червен';
    case TShirtColor.BLUE:
      return 'Син';
    case TShirtColor.DARK_BLUE:
      return 'Тъмносин';
    case TShirtColor.LIGHT_BLUE:
      return 'Светлосин';
    case TShirtColor.DARK_GREEN:
      return 'Тъмнозелен';
    case TShirtColor.YELLOW:
      return 'Жълт';
    case TShirtColor.LIGHT_PINK:
      return 'Светлорозов';
  }
};

export const translateTypeToBulgarian = (type: TShirtType): string => {
  switch (type) {
    case TShirtType.MEN:
      return 'Мъжко';
    case TShirtType.WOMEN:
      return 'Дамско';
    case TShirtType.KIDS:
      return 'Детско';
    case TShirtType.OVERSIZED:
      return 'Oversized';
  }
};

export const addPriceToItems = async (
  originalItems: LocalItem[] | CartProduct[],
  getProductById: (id: string) => Promise<Product | undefined>,
  activeDiscounts: Discount[]
): Promise<CartProduct[]> => {
  const mappedItems: CartProduct[] = [];

  for await (const item of originalItems) {
    const product = await getProductById(item.id);

    if (product) {
      const discount = getDiscountForProduct(product, activeDiscounts);
      const discountedPrice = getDiscountedPrice(product.price, discount);

      const mappedProduct = mapProductToCartProduct(
        product,
        item.color as TShirtColor,
        item.image,
        item.size,
        item.type,
        item.customizationNotes,
        discountedPrice
      );
      mappedItems.push(mappedProduct);
    }
  }

  return mappedItems;
};
