import { useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { toast } from 'react-toastify';

export const useMaintenance = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMaintenanceStatus = async (): Promise<boolean> => {
    setIsLoading(true);

    try {
      const maintenanceRef = doc(db, 'maintenance', '1');
      const maintenance = await getDoc(maintenanceRef);

      return Boolean(maintenance.exists() ? maintenance.data().isOn : false);
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMaintenanceMode = async (isOn: boolean) => {
    setIsLoading(true);

    try {
      await updateDoc(doc(db, 'maintenance', '1'), {
        isOn
      });
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return { getMaintenanceStatus, toggleMaintenanceMode, isLoading };
};
