import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Color } from '../../assets/constants';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import {
  addDoc,
  setDoc,
  collection,
  deleteDoc,
  doc,
  writeBatch
} from '@firebase/firestore';
import { db, storage } from '../../firebase/firebaseConfig';
import { ImageInput } from '../../components/common/ImageInput';
import {
  checkIfFileExists,
  supportedImageTypes,
  supportedVideoTypes
} from './utils';
import { Banner, FileType, useBanners } from '../../hooks/useBanners';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from 'firebase/storage';
import { v4 as uuid4 } from 'uuid';
import { EdittableAndSelectableItems } from '../../components/common/EdittableAndSelectableItems';

export const UpdateBannersContainer = () => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [file, setFile] = useState<File>();
  const [bannerLink, setBannerLink] = useState<string>('');
  const [newBannerIndex, setNewBannerIndex] = useState<number>(0);
  const [bannerIdToEdit, setBannerIdToEdit] = useState<string | null>(null);
  const [isDeletingBanner, setIsDeletingBanner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getBanners, isFetchingBanners } = useBanners();

  const setBannersFromFirebase = async () => {
    const fetchedBanners = await getBanners();
    setBanners(fetchedBanners);
  };

  useEffect(() => {
    setBannersFromFirebase();
  }, []);

  const replaceBannersInFirebase = async (
    currentBanners: Banner[],
    newBanners: Banner[]
  ) => {
    setIsLoading(true);

    // Delete all current banners and add the rearranged ones
    // Not the most efficient way to do this, but it's the simplest
    try {
      for await (const banner of currentBanners) {
        await deleteDoc(doc(db, 'banners', banner.id));
      }
      for await (const banner of newBanners) {
        // Keep the same ID if banner already exists
        if (banner.id) {
          await setDoc(doc(db, 'banners', banner.id), banner);
        } else {
          await addDoc(collection(db, 'banners'), banner);
        }
      }

      toast.success('Banners updated successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsLoading(false);
      return toast.error(`💥 ${e.message}`);
    } finally {
      setBannersFromFirebase();
      setIsLoading(false);
    }
  };

  const uploadFile = async (file: File) => {
    const fileType = file.type.split('/')[0];
    const isVideo = fileType === FileType.VIDEO;

    const bannerId = uuid4();
    const storageRef = ref(
      storage,
      `${isVideo ? 'bannerVideos' : 'bannerImages'}/${bannerId}`
    );
    const snapshot = await uploadBytes(storageRef, file as File);
    const fileUrl = await getDownloadURL(snapshot.ref);

    return { bannerId, fileUrl, fileType };
  };

  const handleAddNewBanner = async () => {
    if (!file) {
      return;
    }

    setIsLoading(true);

    const { bannerId, fileUrl, fileType } = await uploadFile(file);

    const newBanner = {
      id: bannerId,
      name: file.name,
      fileType,
      fileUrl: '',
      redirectUrl: bannerLink,
      index: Math.min(Math.max(0, newBannerIndex), banners.length)
    };

    newBanner.fileUrl = fileUrl;

    const sortedBanners = [...banners].sort((a, b) => a.index - b.index);

    const insertionIndex = sortedBanners.findIndex(
      (banner) => banner.index >= newBanner.index
    );

    if (insertionIndex === -1) {
      sortedBanners.push(newBanner as Banner);
    } else {
      sortedBanners.splice(insertionIndex, 0, newBanner as Banner);
    }

    const updatedBanners = sortedBanners.map((banner, index) => ({
      ...banner,
      index
    }));

    await replaceBannersInFirebase(banners, updatedBanners);
    resetForm();
  };

  const handleEditExistingBanner = async () => {
    const bannerToEdit = banners.find((banner) => banner.id === bannerIdToEdit);
    if (!bannerToEdit) {
      resetForm();
      return toast.error(
        `Cannot find existing banner with ID: ${bannerIdToEdit}`
      );
    }

    const oldIndex = bannerToEdit.index;
    const newIndex = Math.min(Math.max(0, newBannerIndex), banners.length - 1);

    if (
      newIndex === oldIndex &&
      bannerLink === bannerToEdit.redirectUrl &&
      !file
    ) {
      resetForm();
      return toast.info(
        `No changes made to existing banner: ${bannerToEdit.name}`
      );
    }

    let fileUrl = bannerToEdit.fileUrl;
    if (file) {
      const uploadResult = await uploadFile(file);
      fileUrl = uploadResult.fileUrl;
    }

    const updatedBanner = {
      ...bannerToEdit,
      name: file ? file.name : bannerToEdit.name,
      index: newIndex,
      redirectUrl: bannerLink,
      fileUrl: fileUrl
    };

    const newBanners = banners.filter((banner) => banner.id !== bannerIdToEdit);
    newBanners.splice(newIndex, 0, updatedBanner);

    const finalBanners = newBanners.map((banner, index) => ({
      ...banner,
      index
    }));

    await replaceBannersInFirebase(banners, finalBanners);
    resetForm();
  };

  const handleDeleteBanner = async (bannerId: string) => {
    setIsDeletingBanner(true);

    try {
      const bannerToDelete = banners.find((banner) => banner.id === bannerId);
      if (!bannerToDelete) {
        throw new Error('Banner not found');
      }
      const deletedIndex = bannerToDelete.index;

      const imageRef = ref(storage, `bannerImages/${bannerId}`);
      const videoRef = ref(storage, `bannerVideos/${bannerId}`);

      const imageExists = await checkIfFileExists(imageRef);
      const videoExists = await checkIfFileExists(videoRef);

      await deleteDoc(doc(db, 'banners', bannerId));

      if (imageExists) {
        await deleteObject(imageRef);
      }
      if (videoExists) {
        await deleteObject(videoRef);
      }

      const updatedBanners = banners
        .filter((banner) => banner.id !== bannerId)
        .map((banner) => {
          if (banner.index > deletedIndex) {
            return { ...banner, index: banner.index - 1 };
          }
          return banner;
        });

      const batch = writeBatch(db);
      updatedBanners.forEach((banner) => {
        const bannerRef = doc(db, 'banners', banner.id);
        batch.update(bannerRef, { index: banner.index });
      });
      await batch.commit();

      toast.success('Banner deleted successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsDeletingBanner(false);

      return toast.error(`💥 ${e.message}`);
    } finally {
      setBannersFromFirebase();
      setIsDeletingBanner(false);
      resetForm();
    }
  };

  const handleStartEditingBanner = (banner: Banner) => {
    setBannerIdToEdit(banner.id);
    setBannerLink(banner.redirectUrl);
    setNewBannerIndex(banner.index);
  };

  const resetForm = () => {
    setFile(undefined);
    setBannerIdToEdit(null);
    setNewBannerIndex(0);
    setBannerLink('');
    setIsLoading(false);
  };

  const handleActionButtonFunction = !bannerIdToEdit
    ? handleAddNewBanner
    : handleEditExistingBanner;

  const actionButtonLabel = bannerIdToEdit ? 'Edit banner' : 'Add banner';

  return (
    <Wrapper>
      <Text>Banner images</Text>
      <EdittableAndSelectableItems
        items={banners}
        isFetchingItems={isFetchingBanners}
        // @ts-ignore
        handleStartEditingItem={handleStartEditingBanner}
      />
      <Text>{actionButtonLabel}</Text>
      <InputContainer>
        <ImageInput
          fileName={file?.name}
          supportedTypes={[...supportedImageTypes, ...supportedVideoTypes]}
          onChange={(e) => {
            e.target.files && setFile(e.target.files[0]);
          }}
        />
        <LinkAndIndexContainer>
          <LinkInputWrapper>
            <Input
              value={bannerLink}
              placeholder={'Banner link...'}
              onChange={(e) => setBannerLink(e.target.value)}
            />
          </LinkInputWrapper>
          <IndexInputWrapper>
            <Input
              value={newBannerIndex}
              placeholder={'Index...'}
              onChange={(e) => {
                if (!e.target.value.match(/^[0-9]*$/)) {
                  return;
                }
                setNewBannerIndex(Number(e.target.value));
              }}
            />
          </IndexInputWrapper>
        </LinkAndIndexContainer>
      </InputContainer>
      <ButtonContainer>
        <Button
          label={actionButtonLabel}
          disabled={!bannerIdToEdit && file === undefined}
          loading={isLoading}
          onClick={handleActionButtonFunction}
        />
        {bannerIdToEdit && (
          <Button
            label={'Delete banner'}
            backgroundColor={Color.RED}
            loading={isDeletingBanner}
            onClick={() => handleDeleteBanner(bannerIdToEdit)}
          />
        )}
        {bannerIdToEdit && (
          <Button label={'Stop editing'} onClick={resetForm} />
        )}
      </ButtonContainer>
    </Wrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
`;

const Text = styled.p`
  font-size: 24px;
  color: ${Color.WHITE};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 10px 0;
`;

const LinkInputWrapper = styled.div`
  flex: 3;
`;

const IndexInputWrapper = styled.div`
  flex: 1;
`;

const LinkAndIndexContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
