import React from 'react';
import styled from 'styled-components';

interface Props {
  isOn: boolean;
  handleToggle: () => void;
}

const Switch = ({ isOn, handleToggle }: Props) => {
  return (
    <>
      <SwitchCheckbox
        checked={isOn}
        onChange={handleToggle}
        id="react-switch-new"
        type="checkbox"
      />
      <SwitchLabel htmlFor="react-switch-new" isOn={isOn}>
        <SwitchButton />
      </SwitchLabel>
    </>
  );
};

export default Switch;

const SwitchCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const SwitchLabel = styled.label<{ isOn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background-color: ${({ isOn }) => (isOn ? '#06D6A0' : 'grey')};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchButton = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${SwitchCheckbox}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 60px;
  }
`;
